<template>
  <v-container fluid>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      :overlay="false"
      max-width="50%"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          Actualizar Recibido / Enviado
          <v-spacer></v-spacer>
          <v-btn icon color="default" @click="dialog = !dialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-select
            :items="$store.state.quotes.listRecibidoCotizacion"
            item-value="id"
            item-text="descripcion"
            v-model="id_master_recibidocotizacion"
            label="RECIBIDO COTIZACION EN PRICING"
          ></v-select>
          <v-select
            :items="$store.state.quotes.listEnviadoCliente"
            item-value="id"
            item-text="descripcion"
            v-model="id_master_enviadocliente"
            label="ENVIADO AL CLIENTE"
          >
          </v-select>
          <v-text-field
            type="date"
            label="FECHA DE ENVÍO AL CLIENTE"
            v-if="
              $store.state.quotes.listEnviadoCliente.filter(
                (v) => v.id == id_master_enviadocliente && v.codigo == 'SE'
              ).length > 0
            "
            v-model="fecha_enviocliente"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="updateQuoteRecibidoEnviado"
            >Guardar</v-btn
          >
          <v-btn color="red" dark @click="dialog = !dialog">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="row">
      <!--  -->
      <div class="col-4 mb-0 pb-0" v-if="vistaListado == '01'">
        <v-simple-table dense border="1">
          <tbody>
            <tr class="rojo">
              <td>COTIZANDO SEGURO</td>
            </tr>
            <tr class="mostaza">
              <td>COTIZANDO PROSPECTO</td>
            </tr>
            <tr class="amarillo">
              <td>ESPERANDO SEGURO</td>
            </tr>
            <tr class="verde">
              <td>ESPERANDO PROSPECTO</td>
            </tr>
            <tr class="bold">
              <td>FALTA INFORMACIÓN</td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
      <div class="col-4 mb-0 pb-0" v-if="vistaListado == '02'">
        <v-simple-table dense>
          <tbody>
            <tr class="rojo">
              <td>ESPERANDO SEGURO</td>
            </tr>
            <tr class="mostaza">
              <td>CASILLERO SEGURO</td>
            </tr>
            <tr class="amarillo">
              <td>ESPERANDO PROSPECTO</td>
            </tr>
            <tr class="verde">
              <td>CASILLERO PROSPECTO</td>
            </tr>
            <tr class="bold">
              <td>FALTA INFORMACIÓN DEL CLIENTE</td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
      <!-- combo para ver que listado se va a ver -->
      <div class="col-4 offset-4 mb-0 pb-0">
        <v-select
          :items="items"
          label="Visualización del listado de: "
          v-model="vistaListado"
          dense
          outlined
        ></v-select>
      </div>
      <!-- LISTADO DE COTIZACIONES -->
      <div class="col-12 mt-0 pt-0" v-if="vistaListado == '01'">
        <div class="col-6 offset-md-6 mb-0 pb-0 text-right">
          <v-btn
            color="red"
            @click="imprimir('listado')"
            class="mx-1"
            :loading="loading2"
            :disabled="loading2"
            :dark="!loading2"
            small
          >
            <v-icon>mdi-file-pdf-box</v-icon> REPORTE
          </v-btn>
          <v-btn
            color="teal darken-4"
            @click="exportarExcel()"
            class="mx-1"
            :loading="loading3"
            :disabled="loading3"
            :dark="!loading3"
            small
          >
            <v-icon>mdi-file-excel</v-icon> REPORTE
          </v-btn>
          <v-btn
            color="red"
            @click="imprimirMercadeo()"
            class="mx-1"
            :loading="loading"
            :disabled="loading"
            :dark="!loading"
            small
          >
            <v-icon>mdi-file-pdf-box</v-icon> MERCADEO
          </v-btn>
          <v-btn
            color="info"
            @click.stop="drawer2 = !drawer2"
            class="mx-1"
            dark
            small
          >
            Filtrar
          </v-btn>
        </div>

        <TablaDetalle :listado="listadoQuote" />

        <!-- <v-data-table
          :search="search"
          :headers="cabCotizaciones"
          :items="listadoQuote"
          :expanded.sync="expanded"
          :single-expand="singleExpand"
          show-expand
          @click:row="clickRow"
          item-key="index"
        >
       
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Buscar:"
              class="col-4 mx-4"
              dense
            ></v-text-field>
          </template>

          <template #item="{ item }">
            <tr
              :class="{ 'fila-roja': item.status === 'COTIZANDO SEGURO' }"
            ></tr>
          
            <tr :class="{ 'text-red': item.status === 'COTIZANDO SEGURO' }">
              <td>
                <v-btn
                  icon
                  dark
                  x-small
                  color="#FB9514"
                  :to="{ path: '/editar_cotizacion/' + item.id }"
                  v-if="item.status != 'ELIMINADO'"
                >
                  <v-icon dense small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  icon
                  dark
                  x-small
                  color="#E65100"
                  @click="abrirModal(item)"
                  v-if="item.status != 'ELIMINADO'"
                >
                  <v-icon dense small>mdi-receipt-text-send-outline</v-icon>
                </v-btn>
                <v-btn
                  icon
                  dark
                  x-small
                  color="#1A237E "
                  @click="registrarLlamada(item.id)"
                  v-if="item.status != 'ELIMINADO'"
                >
                  <v-icon dense small>mdi-phone</v-icon>
                </v-btn>
                <v-btn
                  icon
                  dark
                  x-small
                  color="#A43542 "
                  @click="eliminar(item.id, item.codigo)"
                  v-if="item.status != 'ELIMINADO'"
                >
                  <v-icon dense small>mdi-delete</v-icon>
                </v-btn>
              </td>
              <td>
                {{ item.created }}
              </td>
              <td>{{ item.recibidocotizacion }}</td>
              <td>{{ item.enviadocliente }}</td>

              <td>
                {{ item.status }}
              </td>
              <td>{{ item.codigo }}</td>
              <td>{{ item.ejecutivo_ventas }}</td>
              <td>{{ item.nombres }}</td>
              <td>{{ item.telefono }}</td>
              <td>{{ item.sentido }}</td>
              <td>{{ item.tipo_de_carga }}</td>
              <td>{{ item.incoterms }}</td>
              <td>{{ item.origen }}</td>
              <td>{{ item.destino }}</td>
              <td>{{ item.fecha_enviocliente }}</td>
              <td>{{ item.call_notes }}</td>
             
            </tr>
          </template>

          <template v-slot:no-data> No se encontraron registros </template>
        </v-data-table> -->
      </div>
      <!-- LISTADO DE LLAMADAS -->
      <div class="col-12" v-if="vistaListado == '02'">
        <div class="col-4 offset-md-8 mb-0 pb-0 text-right">
          <v-btn
            color="red"
            @click="imprimir('llamadas')"
            class="mx-1"
            :loading="loading4"
            :disabled="loading4"
            :dark="!loading4"
            small
          >
            <v-icon>mdi-file-pdf-box</v-icon> Imprimir
          </v-btn>
          <v-btn
            color="info"
            @click.stop="drawer3 = !drawer3"
            class="mx-1"
            dark
            small
          >
            Filtrar
          </v-btn>
          <v-btn
            @click.stop="drawer = !drawer"
            color="teal darken-2"
            dark
            small
            class="mx-1"
          >
            Columnas a Mostrar
          </v-btn>
        </div>
        <v-data-table
          dense
          :headers="cabLlamadas"
          :items="quoteCall"
          :items-per-page="5"
          class="elevation-1 contenido"
          :search="search2"
          :custom-filter="filterOnlyCapsText2"
          multi-sort
          mobile
        >
          <template v-slot:top>
            <v-text-field
              v-model="search2"
              label="Buscar:"
              class="mx-4 ext-uppercase col-4"
              dense
            ></v-text-field>
          </template>

          <template v-slot:item="row">
            <tr
              @dblclick="accionVerCotizacion(row.item.id_quote)"
              :class="{
                'text-red':
                  row.item.status === 'COTIZANDO SEGURO' ||
                  row.item.status === 'COTIZANDO PROSPECTO',
              }"
            >
              <td>
                <v-btn
                  class="ma-2"
                  icon
                  dark
                  x-small
                  color="#A43542 "
                  @click="registrarLlamada(row.item.id_quote)"
                >
                  <v-icon dense small>mdi-phone</v-icon>
                </v-btn>
              </td>
              <td
                v-if="
                  cabLlamadas.filter(
                    (v) => v.value == 'status' && v.estado == true
                  ).length > 0
                "
              >
                {{ row.item.status }}
              </td>
              <td
                v-if="
                  cabLlamadas.filter(
                    (v) => v.value == 'codigo_quote' && v.estado == true
                  ).length > 0
                "
              >
                {{ row.item.codigo }}
              </td>
              <td
                v-if="
                  cabLlamadas.filter(
                    (v) => v.value == 'fecha_ultima_llamada' && v.estado == true
                  ).length > 0
                "
              >
                {{ row.item.fecha_ultima_llamada }}
              </td>
              <td
                v-if="
                  cabLlamadas.filter(
                    (v) => v.value == 'ejecutivo_ventas' && v.estado == true
                  ).length > 0
                "
              >
                {{ row.item.ejecutivo_ventas }}
              </td>
              <td
                v-if="
                  cabLlamadas.filter(
                    (v) => v.value == 'cliente' && v.estado == true
                  ).length > 0
                "
              >
                {{ row.item.cliente }}
              </td>
              <td
                v-if="
                  cabLlamadas.filter(
                    (v) => v.value == 'ultimo_comentario' && v.estado == true
                  ).length > 0
                "
              >
                {{ row.item.ultimo_comentario }}
              </td>
              <td
                v-if="
                  cabLlamadas.filter(
                    (v) => v.value == 'comentario' && v.estado == true
                  ).length > 0
                "
              >
                {{ row.item.ultimo_comentario }}
              </td>
              <td
                v-if="
                  cabLlamadas.filter(
                    (v) => v.value == 'telefono' && v.estado == true
                  ).length > 0
                "
              >
                {{ row.item.telefono }}
              </td>
              <td
                v-if="
                  cabLlamadas.filter(
                    (v) => v.value == 'fecha_solicitud' && v.estado == true
                  ).length > 0
                "
              >
                {{ row.item.fecha_solicitud }}
              </td>
              <td
                v-if="
                  cabLlamadas.filter(
                    (v) => v.value == 'sentido' && v.estado == true
                  ).length > 0
                "
              >
                {{ row.item.sentido }}
              </td>
              <td
                v-if="
                  cabLlamadas.filter(
                    (v) => v.value == 'origen' && v.estado == true
                  ).length > 0
                "
              >
                {{ row.item.origen }}
              </td>
              <td
                v-if="
                  cabLlamadas.filter(
                    (v) => v.value == 'destino' && v.estado == true
                  ).length > 0
                "
              >
                {{ row.item.destino }}
              </td>
              <td
                v-if="
                  cabLlamadas.filter(
                    (v) => v.value == 'incoterms' && v.estado == true
                  ).length > 0
                "
              >
                {{ row.item.incoterms }}
              </td>
              <td
                v-if="
                  cabLlamadas.filter(
                    (v) => v.value == 'tipo_de_carga' && v.estado == true
                  ).length > 0
                "
              >
                {{ row.item.tipo_de_carga }}
              </td>
            </tr>
          </template>
          <template v-slot:no-data> No se encontraron registros </template>
        </v-data-table>
      </div>
    </div>

    <!-- OFF CANVAS -->
    <!-- <v-navigation-drawer v-model="drawer" absolute temporary right>
      <div class="px-2">
        <v-card-title primary-title> Columnas a Mostrar </v-card-title>
        <v-checkbox
          label="label"
          dense
          v-for="cabCotizacion in cabeceraCotizaciones.filter((v) => v.text)"
          v-model="cabCotizacion.estado"
          :key="cabCotizacion.value"
          v-show="vistaListado == '01'"
        >
          <template v-slot:label>
            {{ cabCotizacion.text }}
          </template>
        </v-checkbox>
        <v-checkbox
          label="label"
          dense
          v-for="k in cabeceraLlamadas.filter((v) => v.text)"
          v-model="k.estado"
          :key="k.value"
          v-show="vistaListado == '02'"
        >
          <template v-slot:label>
            {{ k.text }}
          </template>
        </v-checkbox>
      </div>
      <div class="px-2 text-right">
        <v-btn
          color="success"
          @click="visualizacionDeCamposCabCotizacion"
          small
        >
          Cargar
        </v-btn>
      </div>
    </v-navigation-drawer> -->
    <v-navigation-drawer v-model="drawer2" absolute temporary right width="400">
      <div class="px-2">
        <v-card-title primary-title> Filtrar Cotizaciones </v-card-title>
        <div class="row mt-3 mx-1">
          <div class="col-12 py-1">
            <v-autocomplete
              :items="marketingList"
              label="Tipo de Marketing"
              dense
              clearable
              search
              v-model="filtro.idmarketing"
            ></v-autocomplete>
          </div>
          <!-- <v-card-text class="mt-3 px-5 py-1"> -->
          <div class="col-12 py-1">
            <v-autocomplete
              auto-select-first
              :items="status"
              label="Estado de la Cotización"
              dense
              clearable
              v-model="filtro.idstatus"
            ></v-autocomplete>
          </div>
          <div class="col-12 py-1">
            <v-autocomplete
              :items="entities"
              label="Ejecutivo."
              dense
              clearable
              search
              v-model="filtro.identities"
            ></v-autocomplete>
          </div>
          <div class="col-12 py-1">
            <v-autocomplete
              :items="modality"
              label="Sentido*"
              v-model="filtro.idmodality"
              dense
              clearable
            ></v-autocomplete>
          </div>
          <div class="col-12 py-1">
            <v-autocomplete
              :items="shipment"
              label="Carga*"
              dense
              clearable
              v-model="filtro.idshipment"
            >
            </v-autocomplete>
          </div>
          <div class="col-12 py-1">
            <v-autocomplete
              :items="incoterm"
              label="Incoterm*"
              dense
              clearable
              v-model="filtro.idincoterm"
            ></v-autocomplete>
          </div>
          <div class="col-12 py-1">
            <v-text-field
              v-model="filtro.fechainicio"
              type="date"
            ></v-text-field>
          </div>
          <div class="col-12 py-1">
            <v-text-field v-model="filtro.fechafin" type="date"></v-text-field>
          </div>
          <div class="col-12 py-1">
            <v-radio-group v-model="filtro.estado" column>
              <v-radio
                label="Activo"
                color="green"
                value="activo"
                clearable
              ></v-radio>
              <v-radio
                label="Eliminado"
                color="red"
                value="eliminado"
              ></v-radio>
              <v-radio label="Todos" color="blue" value="todos"></v-radio
            ></v-radio-group>
          </div>
        </div>
        <div class="col-12">
          <v-flex text-right>
            <v-btn
              class="mx-1"
              color="info"
              small
              dark
              @click="filtrarListadoQuote()"
            >
              FILTRAR
            </v-btn>
            <v-btn
              class="mx-1"
              color="default"
              small
              dark
              @click="limpiarFiltroListadoQuote()"
            >
              LIMPIAR
            </v-btn>
          </v-flex>
        </div>
      </div>
    </v-navigation-drawer>
    <v-navigation-drawer v-model="drawer3" absolute temporary right width="400">
      <div class="px-2">
        <v-card-title primary-title> Filtrar Llamadas </v-card-title>
        <div class="row mt-3 mx-1">
          <div class="col-12 py-1">
            <v-autocomplete
              auto-select-first
              :items="status"
              label="Estado de la Cotización"
              dense
              clearable
              v-model="filtroLlamada.idstatus"
            ></v-autocomplete>
          </div>
          <div class="col-12 py-1">
            <v-autocomplete
              :items="modality"
              label="Sentido*"
              v-model="filtroLlamada.idmodality"
              dense
              clearable
            ></v-autocomplete>
          </div>
          <div class="col-12 py-1">
            <v-autocomplete
              :items="shipment"
              label="Carga*"
              dense
              clearable
              v-model="filtroLlamada.idshipment"
            >
            </v-autocomplete>
          </div>
          <div class="col-12 py-1">
            <v-autocomplete
              :items="incoterm"
              label="Incoterm*"
              dense
              clearable
              v-model="filtroLlamada.idincoterm"
            ></v-autocomplete>
          </div>
          <div class="col-12 py-1">
            <v-list-tile-sub-title> Desde: </v-list-tile-sub-title>
            <v-text-field
              v-model="filtroLlamada.fechainicio"
              type="date"
            ></v-text-field>
          </div>
          <div class="col-12 py-1">
            <v-list-tile-sub-title> Data: </v-list-tile-sub-title>
            <v-text-field
              v-model="filtroLlamada.fechafin"
              type="date"
            ></v-text-field>
          </div>
        </div>
        <div class="col-12">
          <v-flex text-right>
            <v-btn
              class="mx-1"
              color="info"
              small
              dark
              @click="filtrarListadoCalls()"
            >
              FILTRAR
            </v-btn>
            <v-btn
              class="mx-1"
              color="default"
              small
              dark
              @click="limpiarFiltroLlamadas()"
            >
              LIMPIAR
            </v-btn>
          </v-flex>
        </div>
      </div>
    </v-navigation-drawer>
    <!-- OFF CANVAS -->
  </v-container>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import funcion from "@/mixins/funciones";
import Swal from "sweetalert2";
import axios from "axios";
import moment from "moment";
export default {
  mixins: [funcion],
  name: "listarCotizacionComponent",
  components: {
    TablaDetalle: () => import("../comun/tablaDetalle.vue"),
  },
  data() {
    return {
      singleExpand: true,
      expandedRow: null,
      expanded: [],
      expand: [],
      dialog: false,
      quote: null,
      loading: false,
      loading2: false,
      loading3: false,
      loading4: false,
      search: "",
      search2: "",
      fechaInicioFlag: "",
      fechaFinFlag: "",
      fechaInicioSolicitudFlag: "",
      fechaFinSolicitudFlag: "",
      drawer: false,
      drawer2: false,
      drawer3: false,
      filtro: {
        idmarketing: null,
        idstatus: null,
        identities: null,
        idmodality: null,
        idshipment: null,
        idincoterm: null,
        fechainicio: null,
        fechafin: null,
        estado: "activo",
      },
      filtroLlamada: {
        idmarketing: null,
        idstatus: null,
        identities: null,
        idmodality: null,
        idshipment: null,
        idincoterm: null,
        fechainicio: null,
        fechafin: null,
      },
      cabCotizaciones: [
        {
          value: "acciones",
          text: "ACCIONES",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "created",
          text: "FECHA",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "recibidocotizacion",
          text: "RECIBIDO COTIZACION EN PRICING",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "enviadocliente",
          text: "ENVIADO AL CLIENTE",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "status",
          text: "ESTATUS",
          align: "center",
          groupable: true,
          estado: true,
        },
        // {
        //   value: "terminado",
        //   text: "TERM.",
        //   align: "center",
        //   groupable: true,
        //   estado: true,
        // },
        {
          value: "codigo",
          text: "CÓD.",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "ejecutivo_ventas",
          text: "VENDEDOR",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "nombres",
          text: "NOMBRES",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "telefono",
          text: "TÉLEFONO",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "sentido",
          text: "SENTIDO",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "tipo_de_carga",
          text: "CARGA",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "incoterms",
          text: "INCOTERMS",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "origen",
          text: "ORIGEN",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "destino",
          text: "DESTINO",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "fecha_enviocliente",
          text: "FECHA DE ENVIO AL CLIENTE",
          align: "center",
          groupable: true,
          estado: true,
        },
        {
          value: "call_notes",
          text: "Último comentario",
          align: "center",
          groupable: true,
          estado: true,
        },
        // {
        //   value: "call_pricing",
        //   text: "Pricing Call",
        //   align: "center",
        //   groupable: true,
        //   estado: true,
        // },
        // {
        //   value: "namemarketing",
        //   text: "MARKETING",
        //   align: "center",
        //   groupable: true,
        //   estado: true,
        // },
      ],
      // cabeceraCotizaciones: [
      //   {
      //     value: "acciones",
      //     text: "",
      //     align: "center",
      //     groupable: true,
      //     estado: true,
      //   },
      //   {
      //     value: "created",
      //     text: "FECHA",
      //     align: "center",
      //     groupable: true,
      //     estado: true,
      //   },
      //   {
      //     value: "status",
      //     text: "ESTATUS",
      //     align: "center",
      //     groupable: true,
      //     estado: true,
      //   },
      //   // {
      //   //   value: "terminado",
      //   //   text: "TERMINADO",
      //   //   align: "center",
      //   //   groupable: true,
      //   //   estado: true,
      //   // },
      //   {
      //     value: "codigo",
      //     text: "CÓDIGO",
      //     align: "center",
      //     groupable: true,
      //     estado: true,
      //   },
      //   {
      //     value: "nombres",
      //     text: "NOMBRES",
      //     align: "center",
      //     groupable: true,
      //     estado: true,
      //   },
      //   {
      //     value: "telefono",
      //     text: "TÉLEFONO",
      //     align: "center",
      //     groupable: true,
      //     estado: true,
      //   },
      //   {
      //     value: "sentido",
      //     text: "SENTIDO",
      //     align: "center",
      //     groupable: true,
      //     estado: true,
      //   },
      //   {
      //     value: "tipo_de_carga",
      //     text: "TIPO DE CARGA",
      //     align: "center",
      //     groupable: true,
      //     estado: true,
      //   },
      //   {
      //     value: "incoterms",
      //     text: "INCOTERMS",
      //     align: "center",
      //     groupable: true,
      //     estado: true,
      //   },
      //   {
      //     value: "origen",
      //     text: "ORIGEN",
      //     align: "center",
      //     groupable: true,
      //     estado: true,
      //   },
      //   {
      //     value: "destino",
      //     text: "DESTINO",
      //     align: "center",
      //     groupable: true,
      //     estado: true,
      //   },

      //   {
      //     value: "namemarketing",
      //     text: "MARKETING",
      //     align: "center",
      //     groupable: true,
      //     estado: true,
      //   },
      // ],
      cabLlamadas: [
        { value: "acciones", text: "", align: "center", estado: true },
        { value: "status", text: "STATUS ", aling: "center", estado: true },
        {
          value: "codigo_quote",
          text: "CÓDIGO ",
          aling: "center",
          estado: true,
        },
        {
          value: "fecha_ultima_llamada",
          text: "FECHA ÚLTIMA LLAMADA",
          aling: "center",
          estado: true,
        },
        {
          value: "ejecutivo_ventas",
          text: "EJECUTIVO",
          aling: "center",
          estado: true,
        },
        { value: "cliente", text: "CLIENTE", aling: "center", estado: true },
        {
          value: "ultimo_comentario",
          text: "ULTIMO COMENTARIO",
          aling: "center",
          estado: true,
        },
        { value: "telefono", text: "TELEFONO", aling: "center", estado: true },
        {
          value: "fecha_solicitud",
          text: "FECHA SOLICITUD",
          aling: "center",
          estado: true,
        },
        { value: "sentido", text: "SENTIDO", aling: "center", estado: true },
        { value: "origen", text: "ORIGEN ", aling: "center", estado: true },
        { value: "destino", text: "DESTINO", aling: "center", estado: true },
        {
          value: "incoterms",
          text: "INCOTERMS",
          aling: "center",
          estado: true,
        },
        {
          value: "tipo_de_carga",
          text: "TIPO DE CARGA",
          aling: "center",
          estado: true,
        },
      ],
      cabeceraLlamadas: [
        { value: "acciones", text: "", align: "center", estado: true },
        { value: "status", text: "STATUS ", aling: "center", estado: true },
        {
          value: "codigo_quote",
          text: "CÓDIGO ",
          aling: "center",
          estado: true,
        },
        {
          value: "fecha_ultima_llamada",
          text: "FECHA ÚLTIMA LLAMADA",
          aling: "center",
          estado: true,
        },
        {
          value: "ejecutivo_ventas",
          text: "EJECUTIVO",
          aling: "center",
          estado: true,
        },
        { value: "cliente", text: "CLIENTE", aling: "center", estado: true },
        {
          value: "ultimo_comentario",
          text: "ULTIMO COMENTARIO",
          aling: "center",
          estado: true,
        },
        { value: "telefono", text: "TELEFONO", aling: "center", estado: true },
        {
          value: "fecha_solicitud",
          text: "FECHA SOLICITUD",
          aling: "center",
          estado: true,
        },
        { value: "sentido", text: "SENTIDO", aling: "center", estado: true },
        { value: "origen", text: "ORIGEN ", aling: "center", estado: true },
        { value: "destino", text: "DESTINO", aling: "center", estado: true },
        {
          value: "incoterms",
          text: "INCOTERMS",
          aling: "center",
          estado: true,
        },
        {
          value: "tipo_de_carga",
          text: "TIPO DE CARGA",
          aling: "center",
          estado: true,
        },
      ],
      items: [
        { id: "01", value: "01", text: "Cotizaciones" },
        { id: "02", value: "02", text: "Llamadas" },
      ],
      vistaListado: "01",
      listadoQuote: [],
      id_master_recibidocotizacion: null,
      id_master_enviadocliente: null,
      fecha_enviocliente: "",
    };
  },
  async mounted() {
    this.$store.state.loading = true;
    this.$store.state.progress = 0;
    await this.filterOnlyCapsText();
    this.$store.state.progress = 50;
    await this.getQuoteList();
    this.$store.state.progress = 80;
    await this.getQuoteCall({
      id_estado: null,
      id_sentido: null,
      id_carga: null,
      id_incoterms: null,
      desde: null,
      hasta: null,
    });
    this.$store.state.progress = 90;
    await this.getListRecibidoCotizacion();
    this.$store.state.progress = 95;
    await this.getListEnviadoCliente();

    this.$store.state.loading = false;
    this.$store.state.progress = 0;
  },
  computed: {
    ...mapState([
      "dialogLlamadas",
      "idQuote",
      "quoteList",
      "quoteCall",
      "status",
      "modality",
      "shipment",
      "incoterm",
      "marketingList",
      "entities",
    ]),
  },
  methods: {
    ...mapMutations([
      "limpiarDatos",
      "setDialogLlamadas",
      "setIdQuote",
      "setDialog",
    ]),
    ...mapActions([
      "getQuoteList",
      "getQuoteCall",
      "getListRecibidoCotizacion",
      "getListEnviadoCliente",
      "actualizarQuoteRecibidoEnviado",
    ]),
    clickRow(item, event) {
      if (event.isExpanded) {
        const index = this.expanded.findIndex((i) => i === item);
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(item);
      }
    },
    abrirModal(quote) {
      this.quote = quote;
      this.id_master_recibidocotizacion = quote.id_master_recibidocotizacion;
      this.id_master_enviadocliente = quote.id_master_enviadocliente;
      this.fecha_enviocliente = quote.fecha_enviocliente;
      this.dialog = true;
    },
    async updateQuoteRecibidoEnviado() {
      this.$store.state.loading = true;
      let data = {
        id: this.quote.id,
        id_master_recibidocotizacion: this.id_master_recibidocotizacion,
        id_master_enviadocliente: this.id_master_enviadocliente,
        fecha_enviocliente: this.fecha_enviocliente,
      };
      await this.actualizarQuoteRecibidoEnviado(data);
      await this.getQuoteList();
      this.dialog = false;
      this.$store.state.loading = false;
    },
    filterOnlyCapsText(value, search) {
      let text = search ? search.toLocaleUpperCase() : null;
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(text) !== -1
      );
    },
    filterOnlyCapsText2(value, search2) {
      let text = search2 ? search2.toLocaleUpperCase() : null;
      return (
        value != null &&
        search2 != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(text) !== -1
      );
    },
    /** FIN BUSCAR */

    async eliminar(id, codigo) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ADVERTENCIA",
          text: "DESEA ELIMINAR LA COTIZACIÓN CON CÓDIGO: " + codigo,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ACEPTAR",
          cancelButtonText: "CANCELAR",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.eliminarRegistro(id);
            swalWithBootstrapButtons.fire(
              "ELIMINADO!",
              "COTIZACIÓN " + codigo + " ELIMINADA CON ÉXITO",
              "success"
            );
            this.setDialog(false);
          }
        });
      this.setDialog(false);
    },

    async eliminarRegistro(id) {
      let data = {
        id: id,
      };
      await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "delQuote",
        data
      );
      this.getQuoteList();
      this.setDialogLlamadas(false);
      this.setDialog(false);
    },
    registrarLlamada(id) {
      this.setIdQuote(id);
      this.setDialogLlamadas(true);
    },

    accionVerCotizacion(id) {
      this.limpiarDatos();
      this.$router.push("/ver_cotizacion/" + id);
    },

    visualizacionDeCamposCabCotizacion() {
      let datos = "";
      switch (this.vistaListado) {
        case "01":
          datos = this.cabeceraCotizaciones.filter((v) => v.estado == true);
          this.cabCotizaciones = [];
          this.cabCotizaciones = datos;
          this.drawer = !this.drawer;
          break;
        case "02":
          datos = this.cabeceraLlamadas.filter((v) => v.estado == true);
          this.cabLlamadas = [];
          this.cabLlamadas = datos;
          this.drawer = !this.drawer;
          break;

        default:
          break;
      }
    },
    async imprimir(tipo) {
      this.loading2 = true;
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "aplication/json",
        "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers":
          "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
        responseType: "arraybuffer",
      };
      let url = "";

      if (tipo == "listado") {
        let data = {
          filtro: this.filtro,
          id_branch: JSON.parse(localStorage.getItem("branch")),
        };
        url = process.env.VUE_APP_URL_REPORT + "listado_cotizacion";
        await axios
          .post(url, data, headers)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            // let name = this.uuidv4();
            link.setAttribute(
              "download",
              `Reporte de Cotizaciones ${moment().format(
                "DD-MM-YYYY hh:mm:ss"
              )}.pdf`
            );
            document.body.appendChild(link);
            link.click();
            this.loading2 = false;
          })
          .catch((e) => console.log(e));
      }
      if (tipo == "llamadas") {
        let data = {
          filtro: this.filtroLlamada,
          id_branch: JSON.parse(localStorage.getItem("branch")),
        };
        url = process.env.VUE_APP_URL_REPORT + "reporte_llamadas_export";
        await axios
          .post(url, data, headers)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            // let name = this.uuidv4();
            link.setAttribute(
              "download",
              `Reporte de Cotizaciones ${moment().format(
                "DD-MM-YYYY hh:mm:ss"
              )}.pdf`
            );
            document.body.appendChild(link);
            link.click();
            this.loading2 = false;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    filtrarListadoQuote() {
      let listado = this.quoteList;

      if (this.filtro.idmarketing) {
        listado = listado.filter(
          (v) => v.id_marketing == this.filtro.idmarketing
        );
      }
      if (this.filtro.idstatus) {
        listado = listado.filter((v) => v.statusquote == this.filtro.idstatus);
      }
      if (this.filtro.identities) {
        listado = listado.filter(
          (v) => v.id_vendedor == this.filtro.identities
        );
      }
      if (this.filtro.idmodality) {
        listado = listado.filter(
          (v) => v.id_modality == this.filtro.idmodality
        );
      }
      if (this.filtro.idshipment) {
        listado = listado.filter(
          (v) => v.id_shipment == this.filtro.idshipment
        );
      }
      if (this.filtro.idincoterm) {
        listado = listado.filter(
          (v) => v.id_incoterms == this.filtro.idincoterm
        );
        // (v) => v.id_incoterms == this.filtro.idincoterm
      }
      if (this.filtro.fechainicio) {
        listado = listado.filter(
          (v) => v.fecha_created >= this.filtro.fechainicio
        );
      }
      if (this.filtro.fechafin) {
        listado = listado.filter(
          (v) => v.fecha_created <= this.filtro.fechafin
        );
      }

      if (this.filtro.estado) {
        if (this.filtro.estado == "activo") {
          listado = listado.filter((v) => v.statusmain == 1);
        }
        if (this.filtro.estado == "eliminado") {
          listado = listado.filter((v) => v.statusmain == 0);
        }
      }
      // created
      this.listadoQuote = listado;
      this.drawer2 = false;
    },
    filtrarListadoCalls() {
      this.quoteCall = [];
      let filtros = {
        id_estado: this.filtroLlamada.idstatus,
        id_sentido: this.filtroLlamada.idmodality,
        id_carga: this.filtroLlamada.idshipment,
        id_incoterms: this.filtroLlamada.idincoterm,
        desde: this.filtroLlamada.fechainicio,
        hasta: this.filtroLlamada.fechafin,
      };
      this.getQuoteCall(filtros);
    },
    limpiarFiltroListadoQuote() {
      this.listadoQuote = this.quoteList;
      this.drawer2 = false;
      this.filtro = {
        idmarketing: null,
        idstatus: null,
        identities: null,
        idmodality: null,
        idshipment: null,
        idincoterm: null,
        fechainicio: null,
        fechafin: null,
        estado: "todos",
      };
    },
    limpiarFiltroLlamadas() {
      this.quoteCallList = this.quoteCall;
      this.filtro = {
        idmarketing: null,
        idstatus: null,
        identities: null,
        idmodality: null,
        idshipment: null,
        idincoterm: null,
        fechainicio: null,
        fechafin: null,
      };
      this.drawer3 = false;
    },
    async imprimirMercadeo() {
      this.loading = true;
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "aplication/json",
        "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers":
          "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
        responseType: "arraybuffer",
      };
      let data = {
        filtro: this.filtro,
        id_branch: JSON.parse(localStorage.getItem("branch")),
      };
      let url = process.env.VUE_APP_URL_REPORT + "listado_cotizacion_mercadeo";
      await axios.post(url, data, headers).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        // let name = this.uuidv4();
        link.setAttribute(
          "download",
          `Reporte de Cotizaciones ${moment().format(
            "DD-MM-YYYY hh:mm:ss"
          )}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        // this.previewFlag = false;
        // this.generandoFlag = false;
        this.loading = false;
      });
    },
    async exportarExcel() {
      this.loading3 = true;
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "aplication/json",
        "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers":
          "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
        responseType: "arraybuffer",
      };
      let data = {
        filtro: this.filtro,
        id_branch: JSON.parse(localStorage.getItem("branch")),
      };
      let url = process.env.VUE_APP_URL_REPORT + "listado_cotizacion_xml";
      await axios.post(url, data, headers).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        // let name = this.uuidv4();
        link.setAttribute(
          "download",
          `Reporte de Cotizaciones ${moment().format(
            "DD-MM-YYYY hh:mm:ss"
          )}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        // this.previewFlag = false;
        // this.generandoFlag = false;
        this.loading3 = false;
      });
    },
  },

  watch: {
    async dialogLlamadas() {
      if (this.dialogLlamadas == false) {
        this.$store.state.loading = true;
        await this.getQuoteList();
        this.$store.state.loading = false;
      }
    },
    async registroLlamadaFlag() {
      await this.obtenerDatosLlamadas();
      this.setDialog(false);
    },
    quoteList() {
      this.listadoQuote = this.quoteList;
      this.filtrarListadoQuote();
    },
    // quoteCall() {
    //   this.quoteCallList = this.quoteCall;
    //   this.filtrarListadoCalls();
    // },
  },
};
</script>

<style scope>
.contenido {
  min-height: 500px;
  color: black !important;
  /* font-size: 5px !important; */
}
.v-data-table__empty-wrapper {
  min-height: 500px;
  box-sizing: border-box;
  padding: auto;
}

* {
  box-sizing: border-box;

  /* font-size: .8rem !important ; */
  /* padding: 0 !important; */
  /* margin: 0 !important; */
}

.btn-success {
  background: #5ab55e;
  color: white;
  margin: 1rem;
  border: none;
  border-radius: 1rem;
  padding: 0.5rem;
}
.btn-danger {
  background: #a43542;
  color: white;
  margin: 1rem;
  border: none;
  border-radius: 1rem;
  padding: 0.5rem;
}
.text-red {
  color: red !important;
}
.rojo {
  background: red;
  color: white;
  font-weight: bold;
}
.mostaza {
  background: rgb(191, 143, 0);
  color: white;
  font-weight: bold;
}
.amarillo {
  background: yellow;
  font-weight: bold;
}
.verde {
  background: green;
  color: white;
  font-weight: bold;
}
.bold {
  font-weight: bold;
}
.v-data-table
  /deep/
  tbody
  /deep/
  tr:hover:not(.v-data-table__expanded__content) {
  background: #ffffff !important;
}
tr.rojo:hover td {
  background-color: red !important;
}

tr.mostaza:hover td {
  background-color: rgb(191, 143, 0);
}
tr.amarillo:hover td {
  background-color: yellow;
}
tr.verde:hover td {
  background-color: green;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
